<template>
<div class="bg-donkerblauw sticky inset-x-0 bottom-0">
    <footer class="flex flex-col justify-center text-center w-full pt-3">
        <div class="flex flex-row justify-center pb-3">
            <img src="/public/storage/algemeen/logo_simp.svg" class="w-72" alt="Roblossing">
        </div>
        <div class="pb-3">
            <ul class="flex flex-col justify-center text-center">
                <li><address><a href="mailto:info@roblossing.be">info@roblossing.be</a></address></li>
                <li><address><a href="tel:0474 71 11 01">0474 71 11 01</a></address></li>
                <li><address>Groenplaats 4, 3890 Montenaken</address></li>
            </ul>
        </div>
        <div class="pb-2">
            <ul class="flex flex-col justify-center text-center">
                <li><address>BTW: BE0794.483.250</address></li>
                <li><address>BE93 7512 1216 1167</address></li>
            </ul>
        </div>
    </footer>
</div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

footer {
    @apply text-white;
    @apply text-lg;
}

a {
    @apply text-white;
    @apply underline
}
</style>
