<template>
    <div class="bg-donkerblauw text-white m-0 p-0">
        <ul class="flex md:flex-row flex-col justify-around content-center m-0 p-0 md:h-28">
            <li class="order-2 md:order-1 md:hover:bg-lichtblauw hover:cursor-pointer" @click="goto('/')" :class="{activeSub: isCurrentRoute('home')}">
                <p>Home</p>
            </li>
            <li class="order-3 md:order-2 md:hover:bg-lichtblauw hover:cursor-pointer" @click="goto('/over-ons')" :class="{activeSub: isCurrentRoute('OverOns')}">
                <p>Over ons</p>
            </li>
            <li class="order-1 md:order-3 hover:cursor-pointer" @click="goto('/')">
                <h1><img src="/public/storage/algemeen/logo.svg" alt="Roblossing" class="w-80"></h1>
            </li>
            <li class="order-4 md-order-4 md:hover:bg-lichtblauw hover:cursor-pointer" @click="goto('/projecten')" :class="{activeSub: isCurrentRoute('Projecten')}">
                <p>Projecten</p>
            </li>
            <li class="order-5 md:order-5 md:hover:bg-lichtblauw hover:cursor-pointer" @click="goto('/contact')" :class="{activeSub: isCurrentRoute('Contact')}">
                <p>Contact</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "NavigatieBalk",
    methods: {
        goto: function (link) {
            // als we op de homepagina zijn, dan scrollen we naar de juiste sectie
           return this.$inertia.visit(link);
        },
        isCurrentRoute(prefix) {
            return route().current().startsWith(prefix);
        }
    }
}
</script>

<style scoped>

p {
    @apply text-white;
    @apply font-bold;
    @apply text-xl;
}

li {
    @apply px-8;
    @apply md:py-6;
    @apply py-4;
    @apply h-full;
    @apply text-center;
    @apply flex;
    @apply items-center;
    @apply justify-center;
}

* {
    font-weight: 600;
}

.activeSub {
    @apply bg-lichtblauw;
}


</style>
